// Notes!
// Sass color functions, "darken" and "lighten" are automatically replaced.

html {
    scrollbar-color: $ui-base-color rgba($ui-base-color, 0.25);
  }

  // Change the colors of button texts
  .button {
    &.button-tertiary {
      color: $highlight-text-color;
      background: darken($ui-primary-color, 15%);
      border-color: $highlight-text-color;
    }
  }

  .simple_form .button.button-tertiary {
    color: $highlight-text-color;
  }

  .status-card__actions button,
  .status-card__actions a {
    color: rgba($white, 0.8);

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }

  // Change default background colors of columns
  .column > .scrollable,
  .getting-started,
  .column-inline-form,
  .error-column,
  .regeneration-indicator {
    background: darken($ui-base-color, 15%);
    border: 1px solid $ui-base-color;
    border-top: 0;
  }

  .account-card{
    background: darken($ui-base-color, 4%);
  }

  .getting-started__trends{
    background: $ui-base-color;
  }

  .column > .scrollable.about {
    border-top: 1px solid $ui-base-color;
  }

  .about__meta{
    background: darken($ui-base-color, 8%);
    border: 1px solid $ui-base-color;
  }

  .about__section__title,
  .interaction-modal {
    background: darken($ui-base-color, 8%);
    border: 1px solid $ui-base-color;
  }

  .rules-list li::before {
    background: $ui-highlight-color;
  }

  .directory__card__img {
    background: $ui-base-color;
  }

  .filter-form {
    background: darken($ui-base-color, 8%);
    border-bottom: 1px solid $ui-base-color;
  }

  .column-back-button,
  .column-header {
    background: $ui-base-color;
    border: 1px solid $ui-base-color;

    @media screen and (max-width: $no-gap-breakpoint) {
      border-top: 0;
    }

    &--slim-button {
      top: -50px;
      right: 0;
    }
  }

  .column-header__back-button,
  .column-header__button,
  .column-header__button.active,
  .account__header {
    background: $ui-base-color;
  }

  .column-header__button.active {
    color: $ui-highlight-color;

    &:hover,
    &:active,
    &:focus {
      color: $ui-highlight-color;
      background: $white;
    }
  }

  .account__header__bar .avatar .account__avatar {
    border-color: $white;
  }

  .getting-started__footer a {
    color: $ui-secondary-color;
    text-decoration: underline;
  }

  .confirmation-modal__secondary-button,
  .confirmation-modal__cancel-button,
  .mute-modal__cancel-button,
  .block-modal__cancel-button {
    color: $white;
    background-color: darken($ui-highlight-color, 4%) !important;

    &:hover,
    &:focus,
    &:active {
      color: $white;
      background-color: darken($ui-highlight-color, 12%) !important;
    }
  }

  .column-subheading {
    background: darken($ui-base-color, 8%);
  }

  .getting-started {
    background: darken($ui-base-color, 8%) !important;
  }

  .getting-started,
  .scrollable {
    .column-link {
      background: darken($ui-base-color, 15%);

      &:hover,
      &:active,
      &:focus {
        background: $ui-base-color;
      }
    }
  }

  .getting-started .navigation-bar {
    border-top: 1px solid $ui-base-color;
    border-bottom: 1px solid $ui-base-color;

    @media screen and (max-width: $no-gap-breakpoint) {
      border-top: 0;
    }
  }

  .compose-form__autosuggest-wrapper,
  .poll__option input[type='text'],
  .compose-form .spoiler-input__input,
  .compose-form__poll-wrapper select,
  .search__input,
  .setting-text,
  .report-dialog-modal__textarea,
  .audio-player {
    border: 1px solid lighten($ui-base-color, 8%);
  }

  .report-dialog-modal .dialog-option .poll__input,
  .status .poll .poll__option .poll__input {
    color: $white;
    &.active{
      background:$classic-highlight-color;
      border-color:$classic-highlight-color;
    }
  }

  .poll__input{
    &:active,
    &:focus,
    &:hover{
      border-color: $classic-highlight-color;
    }
  }

  .poll__chart{
    background:$ui-base-lighter-color;
   }

  .search__input {
    background-color: darken($ui-base-color, 15%);
  }

  .list-editor .search .search__input {
    border-top: 0;
    border-bottom: 0;
  }

  .compose-form__poll-wrapper select {
    background: $simple-background-color
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color($ui-base-color)}'/></svg>")
      no-repeat right 8px center / auto 16px;
  }

  .compose-form__poll-wrapper,
  .compose-form__poll-wrapper .poll__footer {
    border-top-color: $ui-base-color;
  }

  .notification__filter-bar {
    border: 1px solid $ui-base-color;
    border-top: 0;
  }

  .compose-form .compose-form__buttons-wrapper {
    background: $ui-base-color;
    border: 1px solid $ui-base-color;
    border-top: 0;
  }
  .drawer__inner .compose-form .compose-form__buttons-wrapper {
    background: $ui-base-color;
    border: 1px solid $ui-base-color;
    border-top: 0;
  }

  .drawer__inner,
  .navigation-bar {
    background: darken($ui-base-color, 8%);
    border: 1px solid $ui-base-color;
  }

  .drawer__header{
    background: $ui-base-color;
    border: 1px solid $ui-base-color;
  }

  .drawer__inner__mastodon {
    background: darken($ui-base-color, 8%)
      url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color($ui-base-color)}"/></svg>')
      no-repeat bottom / 100% auto;
  }

  // Change the colors used in compose-form
  .compose-form {
    .compose-form__modifiers {
      .compose-form__upload__actions .icon-button,
      .compose-form__upload__warning .icon-button {
        color: lighten($white, 7%);

        &:active,
        &:focus,
        &:hover {
          color: $white;
        }
      }
    }

    .autosuggest-textarea__suggestions {
      background: darken($ui-base-color, 6%);
    }

    .autosuggest-textarea__suggestions__item {
      &:hover,
      &:focus,
      &:active,
      &.selected {
        background: lighten($ui-base-color, 4%);
      }
    }
  }

  .emoji-mart-bar {
    border-color: lighten($ui-base-color, 4%);

    &:first-child {
      background: darken($ui-base-color, 6%);
    }
  }

  .emoji-mart-search input {
    background: rgba($ui-base-color, 0.3);
    border-color: $ui-base-color;
  }

  .upload-progress__backdrop {
    background: $ui-base-color;
  }

  // Change the background colors of statuses
  .focusable:focus {
    background: $ui-base-color;
  }

  .detailed-status,
  .detailed-status__action-bar {
    background: darken($ui-base-color, 15%);
  }

  // Change the background colors of status__content__spoiler-link
  .reply-indicator__content .status__content__spoiler-link,
  .status__content .status__content__spoiler-link {
    background: $ui-base-color;

    &:hover,
    &:focus {
      background: lighten($ui-base-color, 4%);
    }
  }

  // Change the background colors of media and video spoilers
  .media-spoiler,
  .video-player__spoiler {
    background: $ui-base-color;
  }

  .privacy-dropdown.active .privacy-dropdown__value.active .icon-button {
    color: $white;
  }

  .account-gallery__item a {
    background-color: $ui-base-color;
  }

  // Change the colors used in the dropdown menu
  .dropdown-menu {
    background: $white;

    &__arrow::before {
      background-color: $white;
    }

    &__item {
      a,
      button {
        background: $white;
      }
    }
  }

  // Change the text colors on inverted background
  .privacy-dropdown__option.active,
  .privacy-dropdown__option:hover,
  .privacy-dropdown__option.active .privacy-dropdown__option__content,
  .privacy-dropdown__option.active .privacy-dropdown__option__content strong,
  .privacy-dropdown__option:hover .privacy-dropdown__option__content,
  .privacy-dropdown__option:hover .privacy-dropdown__option__content strong,
  .dropdown-menu__item a:active,
  .dropdown-menu__item a:focus,
  .dropdown-menu__item a:hover,
  .actions-modal ul li:not(:empty) a.active,
  .actions-modal ul li:not(:empty) a.active button,
  .actions-modal ul li:not(:empty) a:active,
  .actions-modal ul li:not(:empty) a:active button,
  .actions-modal ul li:not(:empty) a:focus,
  .actions-modal ul li:not(:empty) a:focus button,
  .actions-modal ul li:not(:empty) a:hover,
  .actions-modal ul li:not(:empty) a:hover button,
  .language-dropdown__dropdown__results__item.active,
  .admin-wrapper .sidebar ul .simple-navigation-active-leaf a,
  .simple_form .block-button,
  .simple_form .button,
  .simple_form button {
    color: $white;
  }

  .language-dropdown__dropdown__results__item
    .language-dropdown__dropdown__results__item__common-name {
    color: $ui-base-color;
  }

  .language-dropdown__dropdown__results__item.active
    .language-dropdown__dropdown__results__item__common-name {
    color: darken($ui-base-color, 12%);
  }

  .dropdown-menu__separator,
  .dropdown-menu__item.edited-timestamp__history__item,
  .dropdown-menu__container__header,
  .compare-history-modal .report-modal__target,
  .report-dialog-modal .poll__option.dialog-option {
    border-bottom-color: lighten($ui-base-color, 4%);
  }

  .report-modal__target{
    background-color: $ui-base-color;
  }

  .report-dialog-modal__container {
    border-top-color: lighten($ui-base-color, 4%);
  }

  // Change the background colors of modals
  .actions-modal,
  .boost-modal,
  .confirmation-modal,
  .mute-modal,
  .block-modal,
  .report-modal,
  .report-dialog-modal,
  .embed-modal,
  .error-modal,
  .onboarding-modal,
  .compare-history-modal,
  .report-modal__comment .setting-text__wrapper,
  .report-modal__comment .setting-text,
  .announcements,
  .picture-in-picture__header,
  .picture-in-picture__footer,
  .reactions-bar__item {
    background: $white;
    border: 1px solid $ui-base-color;
  }

  .modal-layout{
    background: darken($ui-base-color, 8%);
  }

  .admin-wrapper .sidebar ul{
    a.selected:not(.simple-navigation-active-leaf a){
      background: lighten($ui-base-color, 8%);
    }
    ul{
      background: darken($ui-base-color, 8%);
    }
  }

  body.admin,
  body.lighter{
      background: darken($ui-base-color, 8%);
  }

  .reactions-bar__item:hover,
  .reactions-bar__item:focus,
  .reactions-bar__item:active,
  .language-dropdown__dropdown__results__item:hover,
  .language-dropdown__dropdown__results__item:focus,
  .language-dropdown__dropdown__results__item:active {
    background-color: $ui-base-color;
  }

  .reactions-bar__item.active {
    background-color: mix($white, $ui-highlight-color, 80%);
    border-color: mix($ui-base-color, $ui-highlight-color, 80%);
  }

  .media-modal__overlay .picture-in-picture__footer {
    border: 0;
  }

  .picture-in-picture__header {
    border-bottom: 0;
  }

  .announcements,
  .picture-in-picture__footer {
    border-top: 0;
  }

  .icon-with-badge__badge {
    border-color: $white;
    color: $white;
  }

  .report-modal__comment {
    border-right-color: $ui-base-color;
  }

  .report-modal__container {
    border-top-color: $ui-base-color;
  }

  .column-header__collapsible-inner {
    background: darken($ui-base-color, 4%);
    border: 1px solid $ui-base-color;
    border-top: 0;
  }

  .dashboard__quick-access,
  .focal-point__preview strong,
  .admin-wrapper .content__heading__tabs a.selected {
    color: $white;
  }

  .button.button-tertiary {
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  .button.button-secondary {
    border-color: $darker-text-color;
    color: $darker-text-color;
    background-color: $ui-base-color;
  }

  .flash-message.warning {
    color: lighten($gold-star, 16%);
  }

  .boost-modal__action-bar,
  .confirmation-modal__action-bar,
  .mute-modal__action-bar,
  .block-modal__action-bar,
  .onboarding-modal__paginator,
  .error-modal__footer {
    background: darken($ui-base-color, 6%);

    .onboarding-modal__nav,
    .error-modal__nav {
      &:hover,
      &:focus,
      &:active {
        background-color: darken($ui-base-color, 12%);
      }
    }
  }

  .display-case__case {
    background: $white;
  }

  .embed-modal .embed-modal__container .embed-modal__html {
    background: $white;
    border: 1px solid $ui-base-color;

    &:focus {
      border-color: lighten($ui-base-color, 12%);
      background: $white;
    }
  }

  .react-toggle-track {
    background: $ui-secondary-color;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: darken($ui-secondary-color, 10%);
  }

  .react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background: lighten($ui-highlight-color, 10%);
  }

  // Change the default color used for the text in an empty column or on the error column
  .empty-column-indicator,
  .error-column {
    color: $primary-text-color;
    background: darken($ui-base-color, 15%);
  }

  // Change the default colors used on some parts of the profile pages
  .activity-stream-tabs {
    background: $account-background-color;
    border-bottom-color: $ui-base-color;
  }

  .nothing-here,
  .page-header,
  .directory__tag > a,
  .directory__tag > div {
    background: darken($ui-base-color, 15%);
    border: 1px solid $ui-base-color;

    @media screen and (max-width: $no-gap-breakpoint) {
      border-left: 0;
      border-right: 0;
      border-top: 0;
    }
  }

  .simple_form {
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {
      &:hover {
        border-color: lighten($ui-base-color, 12%);
      }
    }

    input[type='datetime-local'],
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='text'],
    input[type='url'],
    textarea,
    select{
      background-color: darken($ui-base-color, 15%);
      border: 1px solid lighten($ui-base-color, 15%);

      &::placeholder{
        color: darken($black, 25%);
      }
    }
  }

  .picture-in-picture-placeholder {
    background: $white;
    border-color: $ui-base-color;
    color: $ui-base-color;
  }

  .directory__tag > a {
    &:hover,
    &:active,
    &:focus {
      background: $ui-base-color;
    }

    @media screen and (max-width: $no-gap-breakpoint) {
      border: 0;
    }
  }

  .batch-table {
    &__toolbar,
    &__row,
    .nothing-here {
      border-color: $ui-base-color;
    }
  }

  .batch-table{
    &__row--attention{
      .pending-account__header,
      .pending-account__header a{
        color: $black;
      }
    }
    &__row--muted{
      .pending-account__header,
      .pending-account__header a{
        color: darken($black, 35%);
      }
    }
  }

  .activity-stream {
    border: 1px solid $ui-base-color;

    &--under-tabs {
      border-top: 0;
    }

    .entry {
      background: $account-background-color;

      .detailed-status.light,
      .more.light,
      .status.light {
        border-bottom-color: $ui-base-color;
      }
    }

    .status.light {
      .status__content {
        color: $primary-text-color;
      }

      .display-name {
        strong {
          color: $primary-text-color;
        }
      }
    }
  }

  .accounts-grid {
    .account-grid-card {
      .controls {
        .icon-button {
          color: $darker-text-color;
        }
      }

      .name {
        a {
          color: $primary-text-color;
        }
      }

      .username {
        color: $darker-text-color;
      }

      .account__header__content {
        color: $primary-text-color;
      }
    }
  }

  .simple_form {
    .warning {
      box-shadow: none;
      background: rgba($error-red, 0.5);
      text-shadow: none;
    }

    .recommended {
      border-color: $ui-highlight-color;
      color: $ui-highlight-color;
      background-color: rgba($ui-highlight-color, 0.1);
    }
  }

  .compose-form .compose-form__warning {
    border-color: $ui-highlight-color;
    background-color: rgba($ui-highlight-color, 0.1);

    &,
    a {
      color: lighten($ui-highlight-color, 15%);
    }
  }

  .reply-indicator {
    background: transparent;
    border: 1px solid $ui-base-color;
  }

  .dismissable-banner {
    background-color: rgba($ui-base-color, 0.15);
}

  .status__content,
  .reply-indicator__content,
  .account__header__bio .account__header__fields {
    a {
      color: lighten($ui-base-lighter-color, 15%);
    }
    dl{
      border-bottom-color: lighten($ui-primary-color, 8%);
      background-color: darken($ui-primary-color, 15%);
    }
  }

  .button.logo-button {
    color: $white;

    svg {
      fill: $white;
    }
  }

  .notification__filter-bar button.active::after,
  .account__section-headline a.active::after {
    border-color: transparent transparent darken($ui-base-color, 15%);
  }

  .hero-widget,
  .moved-account-widget,
  .memoriam-widget,
  .activity-stream,
  .nothing-here,
  .directory__tag > a,
  .directory__tag > div,
  .card > a,
  .page-header,
  .compose-form .compose-form__warning {
    box-shadow: none;
  }

  .mute-modal select {
    border: 1px solid $ui-base-color;
    background: $simple-background-color
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color($ui-base-color)}'/></svg>")
      no-repeat right 8px center / auto 16px;
  }
